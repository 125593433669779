import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { changeQuotationCurrency, deleteQuotationProductsList, getQuotationData, getQuotationStates, updateQuoation } from "../../redux/actions/utilities/quotationAction";
import { getPriceLists } from "../../redux/actions/utilities/priceListAction";
import { getCurrency } from "../../redux/actions/utilities/currencyAction";
import { getCommercialConditions } from "../../redux/actions/utilities/commecialConditionAction";

import { PDFDownloadLink } from "@react-pdf/renderer";
import { NativeSelect } from '@mantine/core';

import QuotationDownload from "./QuotationDownload";
import { QuotationCard } from "./QuotationCard";
import { QuotationSearcher } from "./quotationSearcher/QuotationSearcher";
import ConfirmationModal from "../utilities/Modal/ConfirmationModal";

import iconArrow_gray from "../../assets/svg/functionalities-icons/arrow_gray.svg";

import "./quotation.css";

export default function Quotation() {
	const dispatch = useDispatch();

	const currencyData = useSelector((state) => state.currency.data);
	const quotation = useSelector((state) => state.quotation);
	const currency = useSelector((state) => state.products.currency);
	const userRol = useSelector((state) => state.auth.user.role);
	const userCompany = useSelector((state) => state.auth.company);
	const commercialConditions = useSelector((state) => state.commercialCondition.commercialConditions)

	const [closeSection, setCloseSection] = useState(false)
	const [showConfirmationModal, setShowConfirmationModal] = useState(false)
	const [observationText, setObservationText] = useState(quotation.data?.observations || "")

	const [selectedCommercialCondition, setSelectedCommercialCondition] = useState(undefined)

	useEffect(() => {
		dispatch(getQuotationData(quotation.quotation));
		dispatch(getPriceLists());
		dispatch(getCurrency());
		dispatch(getQuotationStates())
		dispatch(getCommercialConditions())

		document.title = "Scope | Cotización"
	}, [dispatch, quotation.quotation]);

	useEffect(() => {
		const exist = commercialConditions.find(iterator => iterator._id === quotation.data?.commercialCondition?._id)
		if (exist) setSelectedCommercialCondition(exist.percentage)
		else setSelectedCommercialCondition(undefined)

		setObservationText(quotation.data?.observations)
	}, [quotation])

	const handleCommercialConditionChange = (event) => {
		setSelectedCommercialCondition(event.target.value)
		const updatedData = {
			newCommercialCondition: event.target.selectedOptions[0].attributes.optionid.nodeValue
		}
		dispatch(updateQuoation(updatedData, quotation.quotation, currency))
	}

	const handleDeleteCompleteQuotation = () => {
		dispatch(deleteQuotationProductsList(quotation.quotation, currency));
	};

	const handleStateChange = (event) => {
		setShowConfirmationModal({
			title: "Cambio de estado",
			description: `¿Está seguro que desea cambiar el estado de la cotización a "${event.target.selectedOptions[0].label}"?`,
			value: event.target.value
		})
	}

	const handleObservationChange = () => {
		const updatedData = {
			newObservations: observationText
		}
		dispatch(updateQuoation(updatedData, quotation.quotation, currency))
	}

	const handlerShowConfirmationModal = () => {
		const updatedData = {
			newState: showConfirmationModal?.value
		}
		dispatch(updateQuoation(updatedData, quotation.quotation, currency))
		setShowConfirmationModal(null)
	}

	const formatearNumero = (valor) => {
		return new Intl.NumberFormat("de-DE", {
			minimumFractionDigits: 2,  // Mínimo de 2 decimales
			maximumFractionDigits: 2   // Máximo de 2 decimales
		}).format((valor * 1));
	};

	let precioCosto = 0
	let iva10Costo = 0
	let iva21Costo = 0
	let impuestoCosto = 0
	let precioVenta = 0
	let iva10Venta = 0
	let iva21Venta = 0
	let impuestoVenta = 0

	if (quotation?.data?.products) {
		precioCosto = quotation?.data?.products
			.map((item) => {
				return item.normalizedCountedPrice * item.quantity;
			})
			.reduce((a, b) => a + b, 0)
			.toFixed(2);

		iva10Costo = quotation?.data?.products
			.map((item) => {
				const auxTax = item.price.taxes.filter(tax => tax.IVA < 20)
				if (auxTax[0]) {
					return item.normalizedCountedPrice * (Object.values(auxTax)[0].IVA / 100) * item.quantity;
				}
				return 0;
			})
			.reduce((a, b) => a + b, 0)
			.toFixed(2);

		iva21Costo = quotation?.data?.products
			.map((item) => {
				const auxTax = item.price.taxes.filter(tax => tax.IVA > 20)
				if (auxTax[0]) {
					return item.normalizedCountedPrice * (Object.values(auxTax)[0].IVA / 100) * item.quantity;
				}
				return 0;
			})
			.reduce((a, b) => a + b, 0)
			.toFixed(2);

		impuestoCosto = quotation?.data?.products
			.map((item) => {
				const auxTax = item.price.taxes.filter(tax => tax.II)
				if (auxTax[0]) {
					return item.normalizedCountedPrice * (Object.values(auxTax)[0].II / 100) * item.quantity;
				}
				return 0;
			})
			.reduce((a, b) => a + b, 0)
			.toFixed(2);

		precioVenta = quotation?.data?.products
			.map((item) => {
				return (
					item.normalizedCountedPrice * (item.priceList.percentage / 100 + 1) * item.quantity
				);
			})
			.reduce((a, b) => a + b, 0)
			.toFixed(2);

		iva10Venta = quotation?.data?.products
			.map((item) => {
				const auxTax = item.price.taxes.filter(tax => tax.IVA < 20)
				if (auxTax[0]) {
					return item.normalizedCountedPrice * (Object.values(auxTax)[0].IVA / 100) * (item.priceList.percentage / 100 + 1) * item.quantity;
				}
				return 0;
			})
			.reduce((a, b) => a + b, 0)
			.toFixed(2);


		iva21Venta = quotation?.data?.products
			.map((item) => {
				const auxTax = item.price.taxes.filter(tax => tax.IVA > 20)
				if (auxTax[0]) {
					return item.normalizedCountedPrice * (Object.values(auxTax)[0].IVA / 100) * (item.priceList.percentage / 100 + 1) * item.quantity;
				}
				return 0;
			})
			.reduce((a, b) => a + b, 0)
			.toFixed(2);

		impuestoVenta = quotation?.data?.products
			.map((item) => {
				const auxTax = item.price.taxes.filter(tax => tax.II)
				if (auxTax[0]) {
					return item.normalizedCountedPrice * (Object.values(auxTax)[0].II / 100) * (item.priceList.percentage / 100 + 1) * item.quantity;
				}
				return 0;
			})
			.reduce((a, b) => a + b, 0)
			.toFixed(2);
	}

	const handleCurrencyChange = (value) => {
		dispatch(changeQuotationCurrency(quotation.quotation, value))
		dispatch(getQuotationData(quotation.quotation, value));
	}

	return (
		<div className="cuentas-contenedor">
			<div className="style-section-row" onClick={() => { setCloseSection(!closeSection) }}>
				<h2>Lista de cotización</h2>
				<img alt="arrow-icon" src={iconArrow_gray} style={!closeSection ? { rotate: "180deg" } : {}} />
			</div>
			<hr />
			{!closeSection && <QuotationSearcher />}
			<div className={closeSection ? "d-none" : "quotation-container-grid"}>
				<div>
					{quotation?.data?.products && quotation?.data?.products.length > 0 ?
						quotation?.data?.products.map((item) => {
							return (
								<QuotationCard item={item} key={item._id} />
							);
						}) :
						quotation.client && <span>No existen productos agregados a la cotización</span>}
				</div>
				<div className="quotation-options-container">
					<div className="quotation-options-header">
						<NativeSelect
							label="Condición comercial"
							size="xs"
							value={selectedCommercialCondition}
							onChange={(e) => handleCommercialConditionChange(e)}
						>
							<option hidden selected={selectedCommercialCondition}>Sin condición comercial</option>
							{commercialConditions.length > 0 && commercialConditions.map((commercialCondition) => {
								return (
									<option
										selected={selectedCommercialCondition && quotation.data.commercialCondition?._id === commercialCondition._id}
										value={commercialCondition.percentage}
										optionid={commercialCondition._id}
										key={commercialCondition._id}
									>
										{commercialCondition.name}
									</option>
								);
							})}
						</NativeSelect>
						{currencyData && <NativeSelect
							size="xs"
							onChange={(e) => { handleCurrencyChange(e.target.value) }}
							value={currency}
						>
							<option value={currencyData.name} key={currencyData.name}>{currencyData.name}</option>
							{Object.keys(currencyData.convertTo).map((item) => {
								return (<option value={item} key={item}>{item}</option>);
							})}
						</NativeSelect>}

					</div>
					<div className="quotation-options-observations">
						<h4>Observaciones</h4>
						<textarea value={observationText} onChange={(event) => setObservationText(event.target.value)} />
						{observationText !== quotation.data?.observations &&
							<button onClick={handleObservationChange}>Actualizar</button>
						}
					</div>
					{userRol && userRol.access !== "user" && (
						<section>
							<h5>Costo</h5>
							<hr />
							<div>
								<h3>Precio</h3>
								<h3>{currency} {formatearNumero(precioCosto)}</h3>
							</div>
							<div>
								<h3>Iva 10.5%</h3>
								<h3>{currency} {formatearNumero(iva10Costo)}</h3>
							</div>
							<div>
								<h3>Iva 21.0%</h3>
								<h3>{currency} {formatearNumero(iva21Costo)}</h3>
							</div>
							<div>
								<h3>Imp. interno</h3>
								<h3>{currency} {formatearNumero(impuestoCosto)}</h3>
							</div>
							<div>
								<h4>Total</h4>
								<h4>{currency} {formatearNumero(precioCosto * 1 + iva10Costo * 1 + iva21Costo * 1 + impuestoCosto * 1)}</h4>
							</div>
						</section>
					)}
					<section className="quotation-options-last-section">
						<h5>Venta</h5>
						<hr />
						<div>
							<h3>Precio de venta</h3>
							<h3>{currency} {formatearNumero(precioVenta * 1 + precioVenta * (selectedCommercialCondition || 0) / 100)}</h3>
						</div>
						<div>
							<h3>Iva 10.5%</h3>
							<h3>{currency} {formatearNumero(iva10Venta * 1 + iva10Venta * (selectedCommercialCondition || 0) / 100)}</h3>
						</div>
						<div>
							<h3>Iva 21.0%</h3>
							<h3>{currency} {formatearNumero(iva21Venta * 1 + iva21Venta * (selectedCommercialCondition || 0) / 100)}</h3>
						</div>
						<div>
							<h3>Imp. interno</h3>
							<h3>{currency} {formatearNumero(impuestoVenta * 1 + impuestoVenta * (selectedCommercialCondition || 0) / 100)}</h3>
						</div>
						<div>
							<h4>Total de venta</h4>
							<h4>{currency} {formatearNumero(precioVenta * 1 + iva10Venta * 1 + iva21Venta * 1 + impuestoVenta * 1 +
								((precioVenta * 1 + iva10Venta * 1 + iva21Venta * 1 + impuestoVenta * 1) * (selectedCommercialCondition || 0) / 100)
							)}</h4>
						</div>
						<button onClick={() => handleDeleteCompleteQuotation()}>Vaciar lista</button>
						{quotation?.data?.products.length > 0 &&
							<PDFDownloadLink
								document={
									<QuotationDownload
										company={userCompany}
										data={quotation?.data}
										currencyData={quotation?.data.conversion ? quotation.data.conversion : currencyData}
									/>
								}
								fileName={`SCOPE-${quotation.data.code}.pdf`}
							>
								<button style={userRol && userRol.access === "user" ? { marginBottom: "10px" } : {}}>Descargar lista</button>
							</PDFDownloadLink>
						}
						<div className="quotation-options-state">
							<NativeSelect
								onChange={(event) => handleStateChange(event)}
							>
								{quotation.states && quotation.states.map((state) => {
									return <option value={state._id} key={state._id} selected={quotation.data && state._id === quotation.data.stateID}>{state.name}</option>
								})}
							</NativeSelect>
						</div>
					</section>
				</div>
			</div>
			{showConfirmationModal &&
				<ConfirmationModal
					descriptionText={showConfirmationModal.description}
					handlerShowConfirmationModal={handlerShowConfirmationModal}
					setShowConfirmationModal={setShowConfirmationModal}
					titleText={showConfirmationModal.title}
				/>}
		</div>
	);
}