import React from "react";

import { Modal, Box } from "@mui/material";

import closeModalIcon from "../../../assets/svg/functionalities-icons/cross_gray.svg";

import { modalBoxStyle } from "../utilities";

export default function ConfirmationModal({ descriptionText, handlerShowConfirmationModal, setShowConfirmationModal, titleText }) {
	return (
		<Modal
			open={true}
			onClose={() => {
				setShowConfirmationModal(false);
			}}
			style={{ backdropFilter: "blur(4px)" }}
		>
			<Box sx={modalBoxStyle}>
				<div className="modales-contenedor_texto-div">
					<h4 className="modales-titulo_modal">{titleText}</h4>
					<img
						src={closeModalIcon}
						className="modales-cerrar_modal-img"
						alt="close"
						onClick={() => {
							setShowConfirmationModal(false);
						}}
					/>
					<h5 className="modales-texto_modal">{descriptionText}</h5>
				</div>

				<button className="modales-boton_confirmar modales-boton" type="button" onClick={(e) => handlerShowConfirmationModal(e)}>
					SI
				</button>
				<button
					className="modales-boton_cancelar modales-boton"
					onClick={() => {
						setShowConfirmationModal(false);
					}}
				>
					NO
				</button>
			</Box>
		</Modal>
	);
}
