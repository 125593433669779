import React, { useEffect, useRef, useState } from "react";
import { useSearchParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getProduct } from "../../../redux/actions/productos/productAction";
import { getPriceLists } from "../../../redux/actions/utilities/priceListAction";
import { addProductToQuotation } from "../../../redux/actions/utilities/quotationAction";

import { Box, Modal, Tooltip } from "@mui/material";
import { NativeSelect } from '@mantine/core';
import { notifications } from '@mantine/notifications';

import { v4 as uuidv4 } from "uuid";

import iconWorld from "../../../assets/svg/information-icons/world-icon.svg";
import iconWhiteCross from "../../../assets/svg/functionalities-icons/cross_white.svg";
import iconGrayArrow from "../../../assets/svg/functionalities-icons/flecha-gris-imagen-derecha.svg";
import iconWhiteArrow from "../../../assets/svg/functionalities-icons/flecha-blanca-der-imagen-zoom.svg";
import noImage from "../../../assets/svg/system-images/producto-sin-imagen.svg";
import addQuotationItem from "../../../assets/svg/functionalities-icons/add-quotation-item.svg";
import copyItem from "../../../assets/svg/functionalities-icons/copy-item.svg";
import expandArrow from "../../../assets/svg/menu-icons/flecha-triangular.svg";

import MenuItems from "../../navbar/MenuItems";
import { CurrencyCard } from "../../recommendations/currencyCard/CurrencyCard";

import "./selectedProduct.css";
import { getCurrency } from "../../../redux/actions/utilities/currencyAction";
import { QuotationSelector } from "../quotationSelector/QuotationSelector";

const initialProducto = {
	productoDesactualizado: "",
	precioConIva: "",
	productoCategoria: "",
	productoImagen: "",
	ordenarTexto: "",
};

const SelectedProduct = () => {
	const dispatch = useDispatch();
	const [params] = useSearchParams();

	const product = useSelector((state) => state.product.product);
	const currency = useSelector((state) => state.products.currency);
	const currencyData = useSelector((state) => state.currency.data);
	const priceLists = useSelector((state) => state.priceList.priceList);
	const userRol = useSelector((state) => state.auth.user.role?.access);

	const quotationID = useSelector((state) => state.quotation.quotation);
	const quotationData = useSelector((state) => state.quotation.data?.products)
	const quotations = useSelector((state) => state.quotation.list)

	const [productId] = useState(params.get("id") || "");
	const [openModal, setOpenModal] = useState(false);
	const [openZoomModal, setOpenZoomModal] = useState(false);
	const [imageArray, setImageArray] = useState([]);
	const [zoomOpening, setZoomOpening] = useState(false);
	const [imagenActual, setImagenActual] = useState(0);
	const [detalleProducto, setDetalleProducto] = useState(initialProducto);
	const [selectedPricelist, setSelectedPricelist] = useState({ name: "", percentage: 0 });

	const [seeMore, setSeeMore] = useState(true)

	const containerRef = useRef(null);
	const imageRef = useRef(null);
	const lensRef = useRef(null);
	const resultRef = useRef(null);

	const containerRect = containerRef.current?.getBoundingClientRect();
	containerRef.current?.addEventListener("mousemove", zoomImage);
	const imageRect = imageRef.current?.getBoundingClientRect();
	const lensRect = lensRef.current?.getBoundingClientRect();
	const resultRect = resultRef.current?.getBoundingClientRect();

	useEffect(() => {
		if (productId) dispatch(getProduct(productId, currency));
		dispatch(getPriceLists());
		dispatch(getCurrency());

		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}, []);

	useEffect(() => {
		if (priceLists[0]) setSelectedPricelist(priceLists[0]);
	}, [priceLists]);

	useEffect(() => {
		let objeto = initialProducto;
		if (product) {
			if (product.expiration) {
				if (!product.expiration.isExpirable && product.expiration.expirationDelta === 0) objeto.productoDesactualizado = -1
				else objeto.productoDesactualizado = !(product.isExpirable && Date.parse(new Date()) - Date.parse(product.timestamp) - product.expirationDelta * 24 * 60 * 60 * 1000 > 0)
			}
			else {
				objeto.productoDesactualizado = Date.parse(new Date()) - Date.parse(product.timestamp) - 24 * 60 * 60 * 1000 > 0;
			}

			objeto.precioConIva = (
				product.normalizedCountedPrice * 1 +
				product.normalizedCountedPrice * Object.values(product.price.taxes)
					.map(tax => Object.values(tax)[0] * 0.01)
					.reduce((acc, val) => acc + val, 0)

			).toFixed(2);
			objeto.productoImagen = product.info.images;
			objeto.ordenarTexto = product.info.totalInfo.toUpperCase().split("\n" || "    ");
			setDetalleProducto(objeto);
		}

		if (product && Array.isArray(product.info.images)) {
			let array = [];
			if (product.info.images.length > 1) {
				product.info.images.forEach((element, index) => {
					if (index <= 5) {
						array.push(
							<li
								className="selectedProduct-images-li"
								key={index}
								onClick={() => {
									handleImageChange(index);
								}}
								style={detalleProducto.productoImagen[imagenActual] === element ? { border: "2px solid var(--color-gray-blue)" } : {}}
							>
								<img src={element} alt={`img${index}`} />
							</li>
						);
					}
				});
				setImageArray(array);
			} else {
				setImageArray([]);
			}
		}
		document.title = `Scope | ${product ? product.info.title.toString().toUpperCase() : "Producto"}`

		if (!product) if (productId) dispatch(getProduct(productId, currency));
	}, [product, imagenActual]);

	const handleCurrencyChange = (value) => {
		dispatch(getProduct(productId, value));
	}

	const handleImageClick = () => {
		if (detalleProducto.productoImagen.length > 0)
			setOpenZoomModal(!openZoomModal);
	};

	const handleImageChange = (index) => {
		if (index === -1) {
			setImagenActual(detalleProducto.productoImagen.length - 1);
		} else if (index === detalleProducto.productoImagen.length) {
			setImagenActual(0);
		} else {
			setImagenActual(index);
		}
	};

	const formatearNumero = (valor) => {
		return new Intl.NumberFormat("de-DE", {
			minimumFractionDigits: 2,  // Mínimo de 2 decimales
			maximumFractionDigits: 2   // Máximo de 2 decimales
		}).format((valor * 1))
	};

	if (containerRef.current) {
		resultRef.current.style.backgroundImage = `url(${detalleProducto.productoImagen[imagenActual]})`;
		containerRef.current.addEventListener("mousemove", zoomImage);
	}

	function zoomImage(e) {
		if (detalleProducto.productoImagen.length > 0) setZoomOpening(true);
		const { x, y } = getMousePos(e);

		if (x && y) {
			lensRef.current.style.left = x + "px";
			lensRef.current.style.top = y + "px";

			let fx = resultRect.width / lensRect.width;
			let fy = resultRect.height / lensRect.height;

			resultRef.current.style.backgroundSize = `${imageRect.width * fx}px ${imageRect.height * fy}px`;
			resultRef.current.style.backgroundPosition = `-${x * fx}px -${y * fy}px`;
		}
	}

	function getMousePos(e) {
		let x = e.clientX - containerRect.left - lensRect.width / 2;
		let y = e.clientY - containerRect.top - lensRect.height / 2;

		let maxX = containerRect.width - lensRect.width;
		let maxY = containerRect.height - lensRect.height;

		if (x <= 0) {
			x = 0;
		} else if (x >= maxX) {
			x = maxX;
		}
		if (y <= 0) {
			y = 0;
		} else if (y >= maxY) {
			y = maxY;
		}

		return { x, y };
	}

	const handlePostQuotation = () => {
		dispatch(addProductToQuotation(product, selectedPricelist._id, quotationID, currency));
		notifications.show({
			title: `Item añadido (${productInQuotation ? productInQuotation.quantity + 1 : 1})`,
			message: product.info.title.toUpperCase(),
			autoClose: 1500,
		})
	};

	const handleCopyInfo = () => {
		const textarea = document.createElement("textarea");
		textarea.value =
			product.info.partNumber?.toUpperCase() + " - " + product.info.title.toString().toUpperCase();
		document.body.appendChild(textarea);
		textarea.select();
		document.execCommand("copy");
		document.body.removeChild(textarea);

		notifications.show({
			title: 'Copiado',
			message: 'El número de parte y titulo han sido copiados al portapapeles',
			autoClose: 1500,
		})
	};

	let canAddItem = quotations && quotations.find(quotation => quotation._id === quotationID)
	let productInQuotation = quotationData && product && quotationData.find(objeto => objeto._id === product._id)

	return (
		<div className="selectedProduct-container">
			<MenuItems />
			{product && detalleProducto.ordenarTexto && (
				<>
					{product.info.productIndex.subcategories?.indices ? <div className={`selectedProduct-categoryLink`}>
						{product.info.productIndex._id &&
							<Link to={"/" + product.info.productIndex.name.replace(/ /g, "_") + '-' + product.info.productIndex.subcategories.name.replace(/ /g, "_") + '-' + product.info.productIndex.subcategories.indices.name.replace(/ /g, "_")} key={uuidv4()}>
								<span>Ir al listado</span>
							</Link>
						}
						<hr />
						<span style={{ textTransform: "capitalize" }}>{product.info.productIndex.subcategories.indices.name}</span>
						<img src={iconGrayArrow} alt="flecha-categoría" />
						<span style={{ textTransform: "capitalize" }}>{product.info.productIndex.subcategories.name}</span>
						<img src={iconGrayArrow} alt="flecha-categoría" />
						<span style={{ textTransform: "capitalize" }}>{product.info.productIndex.name}</span>
					</div> : <div className={`selectedProduct-no-categoryLink`}></div>}
					<div className="selectedProducts-selects">
						<NativeSelect label="Lista de precio seleccionada" style={{ width: "330px" }} id="companyName" onChange={(e) => {
							setSelectedPricelist(priceLists.find((priceList) => priceList._id === e.target.value));
						}}>
							{priceLists.map((priceList, index) => {
								return (
									<option value={priceList._id} key={index}>
										{priceList.name}
									</option>
								);
							})}
						</NativeSelect>
					</div>
					<div style={{ display: "flex", justifyContent: "center" }}>
						<div className="selectedProduct-complete-info">
							<div className="selectedProduct-info">
								<div>
									<h5 className="selectedProduct-partNumber">
										Número de parte: <span>{product.info.partNumber?.toUpperCase()}</span>
									</h5>
									<div className="selectedProduct-image-container">
										<section>
											<img
												alt="copy-icon"
												src={copyItem}
												onClick={handleCopyInfo}
												title="Copiar informacion de producto" />
											{canAddItem && <img
												alt="addQuotation-icon"
												src={addQuotationItem}
												onClick={handlePostQuotation}
												title="Agregar item a lista de cotizacion" />}
											{productInQuotation && <span className="quotation-quantity-span">{productInQuotation.quantity}</span>}
										</section>
										<img
											alt="iconGrayArrow"
											src={iconGrayArrow}
											style={detalleProducto.productoImagen.length > 1 ? { transform: "rotate(180deg)" } : { display: "none" }}
											onClick={() => handleImageChange(imagenActual - 1)}
										/>
										<div
											className="selectedProduct-zoom-container"
											ref={containerRef}
											onMouseLeave={() => setZoomOpening(false)}
											onClick={handleImageClick}
										>
											<img
												onMouseEnter={() => { if (detalleProducto.productoImagen.length > 0) setZoomOpening(true) }}
												ref={imageRef}
												className="selectedProduct-image"
												src={detalleProducto.productoImagen[0] ? detalleProducto.productoImagen[imagenActual] : noImage}
												alt="noPicture"
											/>
											<div ref={lensRef} className={`${zoomOpening && "selectedProduct-zoom-lens"}`}></div>
										</div>
										<img
											style={detalleProducto.productoImagen.length > 1 ? {} : { display: "none" }}
											alt="iconGrayArrow"
											src={iconGrayArrow}
											onClick={() => handleImageChange(imagenActual + 1)}
										/>
									</div>
									<ul className="selectedProduct-image-ul">{imageArray}</ul>
								</div>
								<div className="selectedProduct-quotationSelector-mobile">
									<QuotationSelector />
								</div>
								<div>
									<div ref={resultRef} className={zoomOpening ? "selectedProduct-zoom-result" : ""}></div>
									<div className={`selectedProduct-stock-title${product.stock.hasStock ? "" : "-noStock"}`}>
										{product.stock.hasStock ? "En stock" : "Sin stock"}
									</div>
									<div className={`selectedProduct-stock-container${product.stock.hasStock ? "" : "-noStock"}`}>
										<span className="selectedProduct-stock-complete">{product.company.isSupplier && product.stock.value}</span>
										<div className="selectedProduct-stock-unit">
											{product.company.isSupplier ? (
												product.stock.stockDetail && product.stock.stockDetail.map((detail, i) => (
													<Tooltip title={detail.location || ""} key={i} followCursor>
														{i !== product.stock.stockDetail.length - 1 ? (
															<span>
																{ } {detail.location}: {detail.quantity} |
															</span>
														) : (
															<span>
																{detail.location}: {detail.quantity}
															</span>
														)}
													</Tooltip>
												))
											) : (
												<Tooltip title="" key={uuidv4()} followCursor>
													<span>{product.company.isSupplier && product.stock.value}</span>
												</Tooltip>
											)}
										</div>
									</div>
									<div className="selectedProduct-mainInfo-container" style={detalleProducto.productoDesactualizado > 0 ? { border: "solid 2px var(--color-red)" } : {}}>
										{product.expiration ?
											(
												detalleProducto.productoDesactualizado > 0 ?
													<h5 className="selectedProduct-outdated">
														Producto desactualizado. Cargado el {product.timestamp.split("T")[0]}
													</h5>
													: <h5 className="selectedProduct-outdated" style={{ color: "var(--color-grey)" }}>
														Carga del articulo: {product.timestamp.split("T")[0]}
													</h5>
											) :
											detalleProducto.productoDesactualizado > 0 ?
												<h5 className="selectedProduct-outdated">
													Producto desactualizado. Ultima actualización {product.timestamp.split("T")[0]}
												</h5>
												: <h5 className="selectedProduct-outdated" style={{ color: "var(--color-grey)" }}>
													Extracción de articulo: {product.timestamp.split("T")[1].split(':').slice(0, 2).join(':')}hs
												</h5>
										}
										<h4 className="selectedProduct-title">{product.info.title.toString().toUpperCase()}</h4>
										<div className="selectedProduct-prices-container">
											<span style={{
												display: 'flex',
												justifyContent: 'space-between',
												marginTop: "0"
											}}>
												<div>
													{product.company.isSupplier ? (
														<h4>{userRol === "user" ? "Precio de venta" : "Precio sin impuestos"}</h4>
													) : (
														<h4>Precio de contado</h4>
													)}
													<span>
														{currency}{" "}
														{userRol === "user"
															? formatearNumero(
																product.normalizedCountedPrice * 1 + product.normalizedCountedPrice * 0.01 * selectedPricelist.percentage
															)
															: formatearNumero(product.normalizedCountedPrice)}
													</span>
												</div>

												{currencyData && <NativeSelect
													size="xs"
													onChange={(e) => { handleCurrencyChange(e.target.value) }}
													value={currency}
												>
													<option value={currencyData.name} key={currencyData.name}>{currencyData.name}</option>
													{Object.keys(currencyData.convertTo).map((item) => {
														return (<option value={item} key={item}>{item}</option>);
													})}
												</NativeSelect>}
											</span>


											{product.price.taxes.map((tax) => {
												if (Object.values(tax)[0] > 0)
													return (
														<span key={Object.keys(tax)[0]}>
															<h4>{`${Object.keys(tax)[0]} (${Object.values(tax)[0].toFixed(2)}%)`}</h4>
															<span>{currency} {formatearNumero(product.normalizedCountedPrice * (Object.values(tax)[0] * 0.01))}</span>
														</span>
													)
												else return undefined
											})}

											<span>
												<h4>{userRol === "user" ? "Precio de venta final" : "Precio final"}</h4>
												<span>
													{currency}{" "}
													{userRol !== "user"
														? formatearNumero(detalleProducto.precioConIva * 1)
														: formatearNumero(detalleProducto.precioConIva * 1 * (0.01 * selectedPricelist.percentage + 1))}
												</span>
											</span>
										</div>
										{userRol !== "user" && (
											<section className={currency === "USD" ? "" : "product-ARS-currency-theme"}>
												<div>
													<span>Precio de venta: </span>
													<span>
														{currency}{" "}
														{formatearNumero(
															product.normalizedCountedPrice * 1 + product.normalizedCountedPrice * 0.01 * selectedPricelist.percentage
														)}
													</span>
												</div>
												<div>
													<span>Precio de venta final: </span>
													<span>
														{currency}{" "}
														{formatearNumero(
															product.normalizedCountedPrice * 1 +
															product.normalizedCountedPrice * Object.values(product.price.taxes)
																.map(tax => Object.values(tax)[0] * 0.01)
																.reduce((acc, val) => acc + val, 0)
															+ (product.normalizedCountedPrice * 0.01 * selectedPricelist.percentage +
																product.normalizedCountedPrice * Object.values(product.price.taxes)
																	.map(tax => Object.values(tax)[0] * 0.01)
																	.reduce((acc, val) => acc + val, 0) * 0.01 * selectedPricelist.percentage)
														)}
													</span>
												</div>
											</section>
										)}
									</div>
									<div className="selectedProduct-info-container">

										{product.info.productIndex.subcategories?.indices && <>
											<span>
												<h4>Categoría</h4>
												{product.info.productIndex.subcategories?.indices && <span>{product.info.productIndex.subcategories.indices.name}</span>}
											</span>
											<hr />
										</>}
										{userRol !== "user" && (
											<span>
												<h4>{product.company.isSupplier ? "Proveedor" : "Competencia"}</h4>
												<span>{product.company.name}</span>
											</span>
										)}
										{userRol !== "user" && <hr />}
										<span>
											<h4>Marca</h4>
											<span>{product.info.brand || "---"}</span>
										</span>
									</div>
									{(userRol !== "user" || !product.company.isSupplier) && product.info.link && (
										<a
											className="selectedProduct-link-container"
											href={product.info.link}
											target="_blank"
											rel="noreferrer"
										>
											<img src={iconWorld} alt="icono-mundo" />
											<span className="selectedProduct-link-title">Ver página de producto</span>
										</a>
									)}
								</div>
							</div>
							{product.info.totalInfo.length > 3 && (
								<div className="selectedProduct-description-container">
									<h4 className="selectedProduct-description-title">Descripción:</h4>
									<div className="selectedProduct-description-info" >
										{detalleProducto.ordenarTexto.map((producto, index) => {
											return <p key={index} style={seeMore ? { overflow: 'hidden' } : { overflow: 'visible', maxHeight: 'none' }}>{producto}</p>;
										})}
										<div onClick={() => setSeeMore(!seeMore)}>
											<h6>Ver {seeMore ? 'más' : 'menos'}</h6>
											<img src={expandArrow} alt="expand-arrow" style={seeMore ? { rotate: '90deg' } : { rotate: '270deg' }} />
										</div>
									</div>
								</div>
							)}
						</div>

						<div className="selectedProduct-dollar-container">
							<NativeSelect
								style={{ marginBottom: "-5px" }}
								label="Lista de precio seleccionada"
								id="companyNameMaxWidth"
								onChange={(e) => {
									setSelectedPricelist(priceLists.find((priceList) => priceList._id === e.target.value));
								}}>
								{priceLists.map((priceList, index) => {
									return (
										<option value={priceList._id} key={index}>
											{priceList.name}
										</option>
									);
								})}
							</NativeSelect>
							<QuotationSelector />
							<CurrencyCard />
						</div>
					</div>
				</>
			)}
			<div onClick={() => setOpenModal(!openModal)} className="producto-icono-recomendaciones">$</div>
			<Modal open={openModal} key={uuidv4()}>
				<Box>
					<div className="selectedProduct-modal-container">
						<div className="producto-recomendaciones-seccion-iconos">
							<img src={iconWhiteCross} onClick={() => setOpenModal(!openModal)} alt="iconos-cruz" />
						</div>
						<div className="producto-recomendaciones-seccion-recomendaciones">
							{currencyData && <CurrencyCard />}
						</div>
					</div>
				</Box>
			</Modal>
			<Modal open={openZoomModal} key={uuidv4()}>
				<Box>
					<div className="selectedProduct-modal-container">
						<div className="selectedProduct-modal-icons">
							<div>
								{imagenActual + 1}/{detalleProducto.productoImagen.length}
							</div>
							<img src={iconWhiteCross} onClick={handleImageClick} alt="iconos-cruz" />
						</div>
						<div className="selectedProduct-modal-image-container">
							{detalleProducto.productoImagen.length > 1 && <img
								alt="iconWhiteArrow"
								src={iconWhiteArrow}
								style={{ transform: "rotate(180deg)" }}
								onClick={() => handleImageChange(imagenActual - 1)}
							/>}
							<img
								alt="iconos-productoImagen"
								src={detalleProducto.productoImagen[imagenActual]}
								style={{ maxWidth: "90%", maxHeight: "90vh", margin: "auto" }}
							/>
							{detalleProducto.productoImagen.length > 1 && <img alt="iconWhiteArrow" src={iconWhiteArrow} onClick={() => handleImageChange(imagenActual + 1)} />}
						</div>
					</div>
				</Box>
			</Modal>
		</div>
	);
};

export default SelectedProduct;
