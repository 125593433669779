import {
    ACTION_COLLABORATOR_ERROR,
    ACTION_COLLABORATOR_START,
    ACTION_COLLABORATOR_SUCCESS,
    ADD_COLLABORATORS_SUCCESS,
    GET_COLLABORATOR_SUCCESS,
    GET_COLLABORATORS_SUCCESS,
} from "../../types/index";

const initialState = {
    error: false,
    collaborators: {},
    message: "",
    loading: false,
    selectedCollaborator: {},
    count: 1,
    page: 1
};

export default function collaboratorReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_COLLABORATOR_START:
            return {
                ...state,
                error: false,
                loading: true,
            };
        case ACTION_COLLABORATOR_ERROR:
            return {
                ...state,
                error: true,
                message: action.payload,
                loading: false,
            };
        case ACTION_COLLABORATOR_SUCCESS:
            return {
                ...state,
                error: false,
                message: action.payload,
                loading: false,
            };
        case ADD_COLLABORATORS_SUCCESS:
            return {
                ...state,
                error: false,
                collaborators: action.payload.collaborators,
                message: action.payload.message,
                loading: false
            }
        case GET_COLLABORATOR_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                selectedCollaborator: action.payload,
            }
        case GET_COLLABORATORS_SUCCESS:
            return {
                ...state,
                error: false,
                collaborators: action.payload.collaborators,
                message: "",
                loading: false,
                selectedCollaborator: {},
                count: action.payload.count,
                page: action.payload.page
            }
        default:
            return state;
    }
}
