import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";

import ModalAddClient from './ModalAddClient';
import EntityCard from '../EntityCard';

import { getClientsPaginated } from '../../../redux/actions/entities/clientAction';

import iconArrow_gray from "../../../assets/svg/functionalities-icons/arrow_gray.svg";
import iconAddNew from "../../../assets/svg/functionalities-icons/plus_white.svg";

import "../entities.css"
import { TextSearcher } from '../../utilities/components/TextSearcher';
import { useSearchParams } from 'react-router-dom';
import { Pagination, Text } from '@mantine/core';

const Clients = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();

    const clientsReducer = useSelector((state) => state.clients)

    const [activePage, setPage] = useState(searchParams.get('page') !== null ? parseInt(searchParams.get('page')) : 1);
    const [closeSection, setCloseSection] = useState(true)
    const [searcherInput, setSearcherInput] = useState("");

    const [showAddClientModal, setShowAddClientModal] = useState(false)

    document.title = "Scope | Clientes"

    useEffect(() => {
        if (!showAddClientModal) {
            dispatch(getClientsPaginated(activePage));
        }
    }, [showAddClientModal, dispatch])

    const handlePathPage = (event) => {
        setPage(event)

        dispatch(getClientsPaginated(event))
        searchParams.set("page", event);
        setSearchParams(searchParams);
    }

    useEffect(() => {
        setPage(!searchParams.get('page') ? 1 : parseInt(searchParams.get('page')))
    }, [searchParams]);

    return (
        <div className="cuentas-contenedor">
            <div className="style-section-row" onClick={() => { setCloseSection(!closeSection) }}>
                <h2>Clientes</h2>
                <img alt="arrow" src={iconArrow_gray} style={!closeSection ? { rotate: "180deg" } : {}} />
            </div>
            <hr />
            <div className={closeSection ? "" : "d-none"} >
                <div className="entity-filters-container">
                    <TextSearcher placeholder="Nombre, razón social o CUIT" setSearchFilter={setSearcherInput} />
                    <section style={{ width: "100%" }}>
                        <img alt="add" src={iconAddNew} className="entity-addNew-botton" onClick={() => setShowAddClientModal(!showAddClientModal)} />
                        {clientsReducer.count > 0 && !clientsReducer.loading &&
                            <span className="products-totalQuantity">Total de clientes: <b>{clientsReducer.count}</b></span>
                        }
                    </section>
                </div>
                <section className="entity-cards-container">
                    {clientsReducer.clients &&
                        clientsReducer.clients.length > 0 &&
                        clientsReducer.clients.map((client) => {
                            if ((client.info.name && client.info.name.toLowerCase().includes(searcherInput.toLowerCase())) ||
                                (client.info.fantasyName && client.info.fantasyName.toLowerCase().includes(searcherInput.toLowerCase())) ||
                                (client.info.CUIT && client.info.CUIT.toLowerCase().includes(searcherInput.toLowerCase()))) {
                                return (
                                    <EntityCard
                                        key={client._id}
                                        mainText={client.info.name}
                                        secondaryText={client.info.fantasyName}
                                        entityID={client._id}
                                        type="client" />
                                );
                            }
                            return null
                        })}
                </section>
                <div className="pagination-container">
                    <Pagination
                        color="var(--color-gray-blue-dark)"
                        onChange={(event) => handlePathPage(event)}
                        total={Math.ceil(clientsReducer.count / 20)}
                        value={parseInt(clientsReducer.page)}
                    />
                </div>
            </div>
            {showAddClientModal && <ModalAddClient setShowAddClientModal={setShowAddClientModal} />}
        </div>
    )
}

export default Clients