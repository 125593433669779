import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { Modal, Box } from "@mui/material";

import iconoCerrarModal from "../../../assets/svg/functionalities-icons/cross_gray.svg";
import iconArrow_gray from "../../../assets/svg/functionalities-icons/arrow_gray.svg";

import {
	addDashboard,
	editDashboard,
	// getDashboard 
} from "../../../redux/actions/dashboard/dashboardAction";
import SelectIndex from "../../utilities/SelectIndex";

import "../home.css";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "300px",
	height: "auto",
	bgcolor: "#ffffff",
	borderRadius: "10px",
	boxShadow: 24,
	p: "20px",
};

export default function ModalEditGraphic({ cerrarModal, dashboard, open, title }) {
	const dispatch = useDispatch();

	const [data, setData] = useState(!dashboard ? "" : dashboard.data.data);
	const [type, setType] = useState("actual");
	const [variable, setVariable] = useState(!dashboard ? "" : dashboard.data.graphic);
	const [text, setText] = useState(!dashboard ? "" : dashboard.searchText);

	const [openSelect, setOpenSelect] = useState(false);
	const [selectCategory, setSelectCategory] = useState(!dashboard ? { name: "- Categoría -" } : dashboard.product);

	const handleClose = () => {
		cerrarModal();
	};

	const handleButtonClick = () => {
		if (!dashboard) {
			dispatch(addDashboard(data, type, variable, text, selectCategory));
		} else {
			dispatch(editDashboard(data, type, variable, text, selectCategory, dashboard._id));
		}
		cerrarModal();
	};

	const handleCategorySelect = (elementProduct) => {
		setSelectCategory(elementProduct);
		setOpenSelect(false);
	};

	return (
		<Modal open={open} onClose={handleClose} style={{ backdropFilter: "blur(4px)" }}>
			<Box sx={style}>
				<div className="modalEditGraphic-container">
					<div>
						<h4 className="modales-titulo_modal">{title}</h4>
						<img src={iconoCerrarModal} className="modales-cerrar_modal_home-img" alt="close" onClick={handleClose} />
					</div>

					<span
						className="filtros-select_category"
						onClick={() => {
							setOpenSelect(!openSelect);
						}}
					>
						{selectCategory.name.replace(/_/g, ' ')}
						<img src={iconArrow_gray} style={openSelect ? { rotate: "180deg" } : {}} alt="open" />
					</span>
					<SelectIndex openSelectIndex={openSelect} handleCategorySelect={handleCategorySelect} othersCategory={true} />
					<select onChange={(e) => setData(e.target.value)} value={data}>
						<option value="" hidden>
							- Dato -
						</option>
						<option value="price">Precio</option>
						<option value="stock">Stock</option>
					</select>
					<select onChange={(e) => setType(e.target.value)} disabled>
						{/* <option value="" hidden>
							- Tipo estadistica -
						</option>
						<option value="historical">Historico</option> */}
						<option value="actual" selected>
							Actual
						</option>
					</select>
					{type === "historical" && (
						<select onChange={(e) => setVariable(e.target.value)}>
							<option value="" hidden>
								- Periodicidad -
							</option>
							<option value="day">Diario</option>
							<option value="week">Semanal</option>
							<option value="month">Mensual</option>
						</select>
					)}
					{type === "actual" && (
						<select onChange={(e) => setVariable(e.target.value)} value={variable}>
							<option value="" hidden>
								- Grafico -
							</option>
							<option value="barra">Barra</option>
							<option value="dona">Dona</option>
							<option value="torta">Torta</option>
						</select>
					)}
					<input placeholder="Input de texto" onChange={(e) => setText(e.target.value)} value={text} />
					<button
						onClick={handleButtonClick}
						disabled={selectCategory.name === "- Categoría -" || !data || !type || !variable}
					>
						{title} grafico
					</button>
				</div>
			</Box>
		</Modal>
	);
}
