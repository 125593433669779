import React, { useEffect, useState } from 'react'
import { useSearchParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { v4 as uuidv4 } from "uuid";

import { getMarketPlayer, getProductIndices } from '../../../../redux/actions/entities/marketPlayerActions';

import { IndexSearchForm } from './IndexSearchForm'
import { IndexSearchIndices } from './IndexSearchIndices';
import { EmptyModal } from '../../../utilities/Modal/EmptyModal';

import reverseIcon from "../../../../assets/svg/functionalities-icons/arrow-open_white.svg"
import webWorldIcon from "../../../../assets/svg/functionalities-icons/web-world.svg"

import "./indexSearch.css"
import { ModalMarketPlayerLink } from '../modales/ModalMarketPlayerLink';

export const IndexSearch = () => {
  const dispatch = useDispatch();

  const [params] = useSearchParams();
  const [marketPlayerID] = useState(params.get("id") || "");

  const marketPlayer = useSelector((state) => state.marketPlayer.marketPlayers);

  const [selectedIndex, setSelectedIndex] = useState(null);
  const [showEmptyModal, setShowEmptyModal] = useState(false)

  const [showMarketPlayerLinkModal, setMarketPlayerLinkModal] = useState(false)

  useEffect(() => {
    dispatch(getMarketPlayer(marketPlayerID));
    dispatch(getProductIndices(marketPlayerID));
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [dispatch, marketPlayerID, showMarketPlayerLinkModal]);

  const handleSelectedIndex = (index) => {
    setSelectedIndex(index);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (index?.status?.code > 300 && window.screen.width >= 540) setShowEmptyModal(true)
  }

  return (
    <div className="cuentas-contenedor indexSearch-container">
      {!marketPlayer.length &&
        <div className='entity-marketPlayer-container'>
          <section className='entity-backToSection-container'>
            <Link to={"/ActoresDeMercado"} key={uuidv4()}>
              <img alt="reverse" src={reverseIcon} />
            </Link>
            <h3>| {marketPlayer.company.isSupplier ? "Proveedor" : "Competencia"}:</h3>
            <a href={marketPlayer?.webLink} target='_blank' rel="noopener noreferrer" style={{ color: "var(--color-gray-dark)" }}>
              <h4>{marketPlayer.company.name}</h4>
            </a>
          </section>
          <img alt="reverse" src={webWorldIcon} onClick={() => setMarketPlayerLinkModal(true)} />
        </div>
      }
      <IndexSearchForm marketPlayerID={marketPlayerID} selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} />
      <IndexSearchIndices handleSelectedIndex={handleSelectedIndex} />
      {showEmptyModal && selectedIndex && <EmptyModal descriptionText={selectedIndex.status.message || ""} setShowEmptyModal={setShowEmptyModal} titleText="Error" />}
      {showMarketPlayerLinkModal &&
        <ModalMarketPlayerLink
          marketPlayerID={marketPlayerID}
          link={marketPlayer.webLink}
          setMarketPlayerLinkModal={setMarketPlayerLinkModal}
        />}
    </div>
  )
}
