import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { Autocomplete } from '@mantine/core';

import MenuItems from "./MenuItems";
import { NavbarMenu } from "./navbarMenu/NavbarMenu";

import { getNavbarCategories } from "../../redux/actions/utilities/navbarAction";
import { getProducts } from "../../redux/actions/productos/productsAction";

import { v4 as uuidv4 } from "uuid";
import universlaCookie from "universal-cookie";

import homeIcono from "../../assets/svg/menu-icons/menuicono.svg";

import "./navbar.css";
import "bootstrap-icons/font/bootstrap-icons.css";

export default function Navbar() {
	const navegation = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const cookie = new universlaCookie();
	const [searchParams, setSearchParams] = useSearchParams();

	const productsReducer = useSelector((state) => state.products);

	const [navbarSearch, setNavbarSearch] = useState(searchParams.get('textSearch') !== null && location.pathname === "/allProducts" ? searchParams.get('textSearch') : "");
	const [showNavbarMenu, setShowNavbarMenu] = useState(false)

	const invalidCharacters = new RegExp("[{}()\\[\\]<>]");
	const [error, setError] = useState(false);

	useEffect(() => {
		dispatch(getNavbarCategories())
		if (!cookie.get("token")) navegation('/')
	}, [dispatch])

	useEffect(() => {
		setNavbarSearch(location.pathname === "/allProducts" ? searchParams.get('textSearch') : "")
	}, [location, searchParams])

	const handleCloseNavbarMenu = () => {
		setShowNavbarMenu(false)
	}

	const handleNavbarSearch = (value) => {
		setError(invalidCharacters.test(value));
		setNavbarSearch(value);
	}

	const handleSearchClick = () => {
		if (navbarSearch !== "") {
			if (location.pathname !== "/allProducts") {
				navegation(`/allProducts?textSearch=${navbarSearch}`);
			} else {
				searchParams.set("textSearch", navbarSearch);
				setSearchParams(searchParams);
				dispatch(getProducts(["allProducts"], { ...productsReducer, textSearch: navbarSearch, minPrice: "", maxPrice: "" }));
			}
		} else if (location.pathname === "/allProducts") {
			dispatch(getProducts(["allProducts"], { ...productsReducer, textSearch: "", minPrice: "", maxPrice: "" }));
			searchParams.set("textSearch", "");
			setSearchParams(searchParams);
		}
	}

	return (
		<div className="navbar">
			<div className="navbar-container-options">
				<div onClick={() => setShowNavbarMenu(true)} className="navbar-home-a">
					<div className="navbar-accesos-rapidos-contenedor" onClick={() => setShowNavbarMenu(true)} >
						<img src={homeIcono} className="navbar-accesos-rapidos-img" alt="accesos" />
					</div>
				</div>
				<div className="navbar-acsesos-rapidos">
					<MenuItems key={uuidv4()} />
				</div>
			</div>
			<div className="navbar-rigth-colum">
				<div autoComplete="off" className="navbar-barra-busqued">
					<Autocomplete
						value={navbarSearch}
						onChange={handleNavbarSearch}
						onKeyDown={(event) => { if (event.key === 'Enter') handleSearchClick() }}
						radius="xl"
						placeholder="Buscar en todos los productos..."
						error={error}
					/>
				</div>
				<button onClick={handleSearchClick}>Buscar</button>
			</div>
			{showNavbarMenu && <NavbarMenu handleCloseNavbarMenu={handleCloseNavbarMenu} />}
		</div>
	);
}
