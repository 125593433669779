import React from "react";
import { useSelector } from "react-redux";

import { Accordion } from '@mantine/core';

import "./productRecommendation.css";
import "../recommendations.css"

export default function ProductRecommendation({ actualPercentage }) {
	const userRol = useSelector((state) => state.auth.user.role);
	const currency = useSelector((state) => state.products.currency);
	const isSupplier = useSelector((state) => state.products.isSupplier); // TODO -> usar el reducer de products directamente
	const suggested = useSelector((state) => state.products.suggested);

	const formatearNumero = (value) => {
		return new Intl.NumberFormat("de-DE", {
			minimumFractionDigits: 2,  // Mínimo de 2 decimales
			maximumFractionDigits: 2   // Máximo de 2 decimales
		}).format((value * 1));
	};

	return (
		<Accordion variant="contained" className="recommendation-container" defaultValue="productRecommendations-container">
			<Accordion.Item key={1} value="productRecommendations-container">
				<Accordion.Control>
					<div className="productRecommendation-container-circle"
						style={isSupplier ? { backgroundColor: "var(--color-gray-blue)" } : { backgroundColor: "var(--color-gray-blue)" }}>
					</div>
					<span>{isSupplier ? "Principal competencia" : "Proveedor recomendado"}</span>
				</Accordion.Control>
				<Accordion.Panel>
					<div className="productRecommendation-container">
						{suggested ? (
							<div>
								<h3>{suggested.info.title.toUpperCase()}</h3>
								<div>
									<h5>{isSupplier ? "Precio contado" : userRol !== "user" ? "Precio" : "Precio de venta"}</h5>
									<h6>
										{currency}{" "}
										{userRol === "user"
											? formatearNumero(
												suggested.normalizedCountedPrice * 1 +
												suggested.normalizedCountedPrice * 0.01 * actualPercentage
											)
											: formatearNumero(suggested.normalizedCountedPrice * 1)}
									</h6>
								</div>

								{suggested.price.taxes.map((tax) => {
									if (Object.values(tax)[0] > 0)
										return (
											<div key={Object.keys(tax)[0]}>
												<h5>{`${Object.keys(tax)[0]} (${Object.values(tax)[0].toFixed(2)}%)`}</h5>
												<h6>{currency} {formatearNumero(suggested.normalizedCountedPrice * (Object.values(tax)[0] * 0.01))}</h6>
											</div>
										)
									else return undefined
								})}

								<div>
									<h5>{isSupplier ? "" : userRol !== "user" ? "Precio con impuestos" : "Precio venta con impuestos"}</h5>
									<h6>
										{!isSupplier && currency + " "}
										{isSupplier
											? ""
											: userRol !== "user"
												? formatearNumero(
													suggested.normalizedCountedPrice * 1 +
													suggested.normalizedCountedPrice * Object.values(suggested.price.taxes)
														.map(tax => Object.values(tax)[0] * 0.01)
														.reduce((acc, val) => acc + val, 0)
												)
												: formatearNumero(
													suggested.normalizedCountedPrice * 1 +
													suggested.normalizedCountedPrice * Object.values(suggested.price.taxes)
														.map(tax => Object.values(tax)[0] * 0.01)
														.reduce((acc, val) => acc + val, 0)

													* actualPercentage * 0.01
												)}
									</h6>
								</div>
								<div>
									{userRol !== "user" && <h4>{suggested.company.name}</h4>}
									{suggested.info.brand && <h4>{suggested.info.brand}</h4>}
									{suggested.info.partNumber && <h4>{suggested.info.partNumber?.toUpperCase()}</h4>}
								</div>
							</div>
						) : (
							<div>
								<span>Sin resultados</span>
							</div>
						)}
					</div>
				</Accordion.Panel>
			</Accordion.Item>
		</Accordion>
	);
}
