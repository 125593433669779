import React from 'react'
import { Progress, Box, Text, Group, Paper, SimpleGrid } from '@mantine/core';

import './statsSegments.css'

export const StatsSegments = ({ data, length }) => {


    const dataCompleted = data.map((item) => {
        return (
            {
                label: item._id, count: item.quantity, part: (item.quantity * 100 / length).toFixed(2), color: item._id === "Activa" ? "var(--color-gray-blue-ligther)" :
                    item._id === "Enviada" ? "var(--color-gray-blue)" :
                        item._id === "Aceptada" ? "var(--color-green)" :
                            item._id === "Rechazada" ? "var(--color-red)" :
                                "var(--color-gray-dark)"
            }
        )
    })

    const segments = dataCompleted.map((segment) => (
        <Progress.Section value={segment.part} color={segment.color} key={segment.label}>
            {segment.part > 10 && <Progress.Label color={segment.color}>{segment.part}%</Progress.Label>}
        </Progress.Section>
    ));

    const descriptions = dataCompleted.map((stat) => (
        <Box key={stat.label} style={{ borderBottom: `4px solid ${stat.color}` }} className="stat">
            <Text tt="uppercase" fz="xs" c="dimmed" fw={700}>
                {stat.label}
            </Text>

            <Group justify="space-between" align="flex-end" gap={0}>
                <Text fw={700}>{stat.count}</Text>
                <Text c={stat.color} fw={700} size="sm" className="statCount">
                    {stat.part}%
                </Text>
            </Group>
        </Box>
    ));

    return (
        <Paper withBorder radius="md">
            <Text className='statsSegment-title'>Total cotizaciones: {length}</Text>
            <Progress.Root size={25} mt={40}>
                {segments}
            </Progress.Root>
            <SimpleGrid cols={{ base: 1, xs: 3 }} mt="xl">
                {descriptions}
            </SimpleGrid>
        </Paper>
    );
}
