import React from "react";
import {
	Document,
	Page,
	Text,
	View,
	Image
} from "@react-pdf/renderer";

import logoAxonScopeHorizontal from "../../assets/svg/axonscope/logo_horizontal.png"

export default function QuotationDownload({ company, data, currencyData }) {
	const pricesStyles = {
		display: "flex",
		flexDirection: "row",
		alignContent: "center",
		justifyContent: "space-between",
		padding: "4.5px 0",
		width: "100%",
	};

	const totalStyle = {
		...pricesStyles,
		borderTop: "1px solid #4d4d4d",
		fontWeight: "bold",
	};

	const formatearNumero = (value) => {
		return new Intl.NumberFormat("de-DE", {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		}).format((value * 1));
	};

	let precioVenta = data?.products
		.map((item) => {
			return item.normalizedCountedPrice * (item.priceList.percentage / 100 + 1) * item.quantity;
		})
		.reduce((a, b) => a + b, 0)
		.toFixed(2);
	precioVenta = precioVenta * 1 + precioVenta * (data.commercialCondition ? data.commercialCondition.percentage : 0) / 100

	let iva10Venta = data?.products
		.map((item) => {
			const auxTax = item.price.taxes.filter(tax => tax.IVA < 20)
			if (auxTax[0]) {
				return item.normalizedCountedPrice * (Object.values(auxTax)[0].IVA / 100) * (item.priceList.percentage / 100 + 1) * item.quantity;
			}
			return 0;
		})
		.reduce((a, b) => a + b, 0)
		.toFixed(2);
	iva10Venta = iva10Venta * 1 + iva10Venta * (data.commercialCondition ? data.commercialCondition.percentage : 0) / 100


	let iva21Venta = data?.products
		.map((item) => {
			const auxTax = item.price.taxes.filter(tax => tax.IVA > 20)
			if (auxTax[0]) {
				return item.normalizedCountedPrice * (Object.values(auxTax)[0].IVA / 100) * (item.priceList.percentage / 100 + 1) * item.quantity;
			}
			return 0;
		})
		.reduce((a, b) => a + b, 0)
		.toFixed(2);
	iva21Venta = iva21Venta * 1 + iva21Venta * (data.commercialCondition ? data.commercialCondition.percentage : 0) / 100

	let impuestoVenta = data?.products
		.map((item) => {
			const auxTax = item.price.taxes.filter(tax => tax.II)
			if (auxTax[0]) {
				return item.normalizedCountedPrice * (Object.values(auxTax)[0].II / 100) * (item.priceList.percentage / 100 + 1) * item.quantity;
			}
			return 0;
		})
		.reduce((a, b) => a + b, 0)
		.toFixed(2);
	impuestoVenta = impuestoVenta * 1 + impuestoVenta * (data.commercialCondition ? data.commercialCondition.percentage : 0) / 100

	const pageQuantity = Array.from({ length: Math.ceil(data.products.length / 18) }, (_, i) => i * 1);

	return (
		<Document>
			{pageQuantity.map((pageNumber) =>
				<Page size="A4" key={pageNumber}>
					<View style={{ height: "100vh", marginLeft: "2.5%", padding: "10px 0", width: "95%" }}>
						<View style={{ display: "flex", alignContent: "center", flexDirection: "row", justifyContent: "space-between", padding: "14px" }}>
							{/* <View style={{ fontSize: "10px", display: "flex", flexDirection: "row", alignItems: "center", gap: "15px" }}>
							<Text>Cotización dolar: ${currencyPrice}</Text>
							<Text>{date}</Text>
						</View> */}
							<View style={{ width: "30%" }}>
								<Text style={{ fontSize: "14px", color: "#3c4d66" }}>{company.name}</Text>
								<Text style={{ fontSize: "8px" }}>{company.address}</Text>
								<Text style={{ fontSize: "8px" }}>{company.phone}</Text>
								<Text style={{ fontSize: "8px" }}>{company.CUIT}</Text>
							</View>
							<View style={{ width: "40%", display: "flex", alignContent: "center", justifyContent: "center", flexDirection: "column", padding: "14px" }}>
								<Text style={{ fontSize: "18px", marginLeft: "50%" }}>X</Text>
								<Text style={{ fontSize: "9px", marginLeft: "15%" }}>Documento no válido como factura</Text>
							</View>
							<View style={{ display: "flex", justifyContent: "center", alignContent: "center", width: "30%" }}>
								{/* <Text style={{ fontSize: "16px", color: "#3c4d66" }}>{company.image}</Text>*/}
							</View>
						</View>

						<View style={{ width: "100%", borderBottom: "1px solid #3c4d66", display: "flex", flexDirection: "row", marginTop: "10px" }}>
							<Text style={{ fontSize: "10px" }}>Presupuesto</Text>
							<Text style={{ fontSize: "10px", color: "#3c4d66", marginLeft: "5px" }}>#{data.code}</Text>
							<Text style={{ fontSize: "10px", marginLeft: "10px" }}>(Cotización en {data.currency})</Text>
						</View>

						<View style={{ display: "flex", flexDirection: "row", fontSize: "10px", marginTop: "10px", width: "100%" }}>
							<View style={{ display: "flex", flexDirection: "column", width: "50%" }}>
								<View style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
									<Text>Cliente:</Text>
									<Text>{data.clientInfo.info.name}</Text>
								</View>
								<View style={{ display: "flex", flexDirection: "row", gap: "5px", marginBottom: "10px" }}>
									<Text>CUIT:</Text>
									<Text>{data.clientInfo.info.CUIT}</Text>
								</View>
								{data.clientInfo.directions && <View style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
									<Text>Provincia:</Text>
									<Text>{data.clientInfo.directions[0]?.province} - {data.clientInfo.directions[0]?.country}</Text>
								</View>}
								<View style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
									<Text>Localidad:</Text>
									<Text>{data.clientInfo.directions[0]?.neighborhood} - {data.clientInfo.directions[0]?.locality} ({data.clientInfo.directions[0]?.postalCode})</Text>
								</View>
								{data.clientInfo.directions && <View style={{ display: "flex", flexDirection: "row", gap: "5px", marginBottom: "10px" }}>
									<Text>Direccion:</Text>
									<Text>{data.clientInfo.directions[0]?.street} {data.clientInfo.directions[0]?.number} - {data.clientInfo.directions[0]?.floor}</Text>
								</View>}
								<View style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
									<Text>Forma de pago:</Text>
									<Text>{data.commercialCondition?.name || "No especifíca"}</Text>
								</View>
							</View>
							<View style={{ display: "flex", flexDirection: "column", width: "50%" }}>
								<View style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
									<Text>Fecha de carga:</Text>
									<Text>{data.creationDate.split("T")[0].split("-").reverse().join("/")}</Text>
								</View>
								<View style={{ display: "flex", flexDirection: "row", gap: "5px", marginBottom: "10px" }}>
									<Text>Fecha última actualización:</Text>
									<Text>{data.updateDate.split("T")[0].split("-").reverse().join("/")}</Text>
								</View>
								<View style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
									<Text>Vendedor:</Text>
									<Text>{data.collaboratorInfo.lastName} {data.collaboratorInfo.name}</Text>
								</View>
							</View>
						</View>

						<View style={{ fontSize: "9px", marginTop: "10px" }}>
							<View
								style={{
									borderBottom: "1px solid #4d4d4d",
									backgroundColor: "#cfcdcd",
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									height: "26px",
									justifyContent: "center",
								}}
							>
								<Text style={{ width: "5%", paddingLeft: "3px" }}>Cant.</Text>
								<Text style={{ width: "45%" }}>Detalle</Text>
								<Text style={{ width: "14%" }}>Nro. parte</Text>
								<Text style={{ width: "13%" }}>Precio sin IVA</Text>
								<Text style={{ width: "6%" }}>IVA</Text>
								<Text style={{ width: "6%" }}>I.I.</Text>
								<Text style={{ width: "12%" }}>SUBTOTAL</Text>
							</View>
							{data.products.slice(pageNumber * 18, pageNumber * 18 + 18).map((item, index) => {
								let styles = {
									borderBottom: "1px solid #4d4d4d",
									display: "flex",
									alignItems: "center",
									flexDirection: "row",
									minHeight: "16px",
									gap: "3px",
								};
								if (index % 2 !== 0) {
									styles = {
										...styles,
										backgroundColor: "#cfcdcd",
									};
								}

								return (
									<View key={item._id} style={styles}>
										<Text style={{ width: "5%", paddingLeft: "5px" }}>{item.quantity}</Text>
										<Text style={{ width: "45%" }}>{item.info.title.toUpperCase()}</Text>
										<Text style={{ width: "14%", overflow: "hidden" }}>{item.info.partNumber.toUpperCase()}</Text>
										<Text style={{ width: "13%" }}>
											{data.currency}{" "}
											{formatearNumero(
												item.normalizedCountedPrice * 1 *
												(item.priceList.percentage * 1 / 100 + 1),
												item.price.currency
											)}
										</Text>
										<Text style={{ width: "6%" }}>{formatearNumero(Object.values(item.price.taxes)[0].IVA || 0)}%</Text>
										<Text style={{ width: "6%" }}>{formatearNumero(Object.values(item.price.taxes)[0].II || 0)}%</Text>
										<Text style={{ width: "12%" }}>
											{data.currency}{" "}
											{formatearNumero(
												(item.normalizedCountedPrice * 1 +
													item.normalizedCountedPrice * 0.01 * (Object.values(item.price.taxes)[0].IVA || 0) +
													item.normalizedCountedPrice * 0.01 * (Object.values(item.price.taxes)[0].II || 0)) *
												(item.priceList.percentage * 1 / 100 + 1) *
												item.quantity,
												item.price.currency
											)}
										</Text>
									</View>
								);
							})}
						</View>

						<View style={{ display: "flex", flexDirection: "column", marginTop: "25px" }}>
							<Text style={{ fontSize: "12px", marginBottom: "5px" }}>Observaciones:</Text>
							<Text style={{ fontSize: "9px" }}>{data.observations}</Text>
						</View>

						<View
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								margin: "10px 2.5%",
								position: "absolute",
								width: "90%",
								bottom: "0",
							}}
						>
							<View
								style={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "center",
									gap: "84px"
								}}>
								<View
									style={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
										fontSize: "8px",
										width: "70%",
										opacity: "0.5"
									}}>
									<Text>Powered by</Text>
									<Image src={logoAxonScopeHorizontal} style={{ height: "20px", width: "90px", padding: "2px 0" }} alt="image" />
								</View>
								<View
									style={{
										display: "flex",
										flexDirection: "column",
										fontSize: "10px",
										width: "30%",
									}}>
									{currencyData?.name !== data.currency && <View style={{ display: "flex", flexDirection: "column" }}>
										<Text style={{ fontSize: "9px" }}>Cotización: </Text>
										<Text style={{ marginBottom: "15px", fontSize: "9px" }}>{data.currency} 1,00 = ${currencyData?.convertTo[data.currency].value} ({currencyData?.name}) </Text>
									</View>}
									<View style={pricesStyles}>
										<Text>Subtotal</Text>
										<Text>{data.currency} {formatearNumero(precioVenta)}</Text>
									</View>
									<View style={pricesStyles}>
										<Text>IVA 10.5%</Text>
										<Text>{data.currency} {formatearNumero(iva10Venta)}</Text>
									</View>
									<View style={pricesStyles}>
										<Text>IVA 21%</Text>
										<Text>{data.currency} {formatearNumero(iva21Venta)}</Text>
									</View>
									<View style={pricesStyles}>
										<Text>Impuesto Interno</Text>
										<Text>{data.currency} {formatearNumero(impuestoVenta)}</Text>
									</View>
									<View style={totalStyle}>
										<Text>Total</Text>
										<Text>{data.currency} {formatearNumero(precioVenta * 1 + iva10Venta * 1 + iva21Venta * 1 + impuestoVenta * 1)}</Text>
									</View>
								</View>
							</View>
							<View style={{
								width: "100%",
								display: "flex",
								justifyContent: "center",
								alignContent: "center",
								fontSize: "10px",
								marginLeft: "50%"
							}}>
								<Text>Pagina {pageNumber + 1}/{Math.ceil(data.products.length / 18)}</Text>
							</View>
						</View>
					</View>
				</Page>)}
		</Document>
	);
}